<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t('MENU.discounts') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
      </div>
      <div class="card-body">
        <div class="card-body p-0">
          <div class="form-group row">

            <div class="col-lg-6 mb-5">
              <label>{{ $t('discounts.name') }}<span class="text-danger">*</span></label>
              <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('offers.name')"/>
              <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.name[0] }}
                </span>
            </div>


            <div class="col-lg-6 mb-5">
              <label>{{ $t('discounts.number_users') }}<span class="text-danger">*</span></label>
              <input type="number" v-model="data.number_users" class="form-control" :class="validation && validation.number_users ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.number_users" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.number_users[0] }}
                </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('discounts.percentage') }}<span class="text-danger">*</span></label>
              <input type="number" v-model="data.percentage" class="form-control" :class="validation && validation.percentage ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.percentage" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.percentage[0] }}
                </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('discounts.start_date') }}<span class="text-danger">*</span></label>
              <input type="date" v-model="data.start_date" class="form-control" :class="validation && validation.start_date ? 'is-invalid' : ''" :placeholder="$t('offers.start_date')"/>
              <span v-if="validation && validation.start_date" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.start_date[0] }}
                </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('discounts.end_date') }}<span class="text-danger">*</span></label>
              <input type="date" v-model="data.end_date" class="form-control" :class="validation && validation.end_date ? 'is-invalid' : ''" :placeholder="$t('offers.end_date')"/>
              <span v-if="validation && validation.end_date" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.end_date[0] }}
                </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('discounts.applied_interval') }}<span class="text-danger">*</span></label>
              <div class="input-group">
                <multiselect
                    :class="validation && validation.applied_interval ? 'is-invalid' : ''"
                    v-model="data.applied_interval"
                    :placeholder="$t('discounts.applied_interval')"
                    :options="interval_list"
                    :multiple="true"
                    :taggable="false"
                    :show-labels="false"
                    :show-no-options="false"
                    :show-no-results="false"
                >
                </multiselect>
                <span v-if="validation && validation.applied_interval" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.applied_interval[0] }}
                </span>
              </div>
            </div>
            <div class="col-lg-6 mb-5">
              <label>&ensp;</label>
              <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('offers.notes') }}</label>
              <textarea v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''" :placeholder="$t('plans.notes')"></textarea>
              <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.notes[0] }}
                    </span>
            </div>

          </div>
        </div>
        <div class="card-footer pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6">
              <button type="reset" class="btn btn-primary mr-2" @click="save()">{{ $t('save') }}</button>
              <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-offers",
  data() {
    return {
      mainRouteDependency: 'base/dependency',
      mainRoute: 'subscription/plan_discounts',
      data: {
        plan_id: this.$route.params.plan_id ? this.$route.params.plan_id : null,
        name: null,
        number_users: null,
        start_date: null,
        end_date: null,
        is_active: true,
        notes: null,
        applied_interval: [],
        percentage: null,
      },

      isEditing: false,
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      validation: null,
      interval_list: [3,6,12],


    };
  },

  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push({name: 'plans-discounts.index'});
      })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    update() {
      ApiService.put(this.mainRoute + `/${this.$route.params.id}`, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$router.push({name: 'plans-discounts.index'});
        this.$successAlert(response.data.message);
      }).catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      ApiService.get(this.mainRoute + `/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.plan_id = response.data.data.plan_id;
        this.data.name = response.data.data.name;
        this.data.number_users = response.data.data.number_users;
        this.data.percentage = response.data.data.percentage;
        this.data.start_date = response.data.data.start_date;
        this.data.end_date = response.data.data.end_date;
        this.data.applied_interval = response.data.data.applied_interval;
        this.data.is_active = response.data.data.is_active;
        this.data.notes = response.data.data.notes;

      });
    },


  },


  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.discounts"), route: '/subscription/plans-discounts'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);
    if (this.idEditing) {
      this.getData();
    }
  },
};
</script>


